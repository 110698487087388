// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { Chip } from '@mui/material';
import { useMemo } from 'react';
import styles from '../styles/IssuePullRequestStatusLabel.module.css';

interface IssuePullRequestStatusLabelProps {
    status: string;
}

export default function IssuePullRequestStatusLabel(props: IssuePullRequestStatusLabelProps) {
    const { status } = props;
    return (
        <Chip
            className={styles.chip}
            data-status={status}
            label={status.charAt(0).toUpperCase() + status.slice(1)}
            size="small"
        />
    );
}
