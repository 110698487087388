// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { gql, useQuery } from '@apollo/client';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Chip, Tooltip, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ReactComponent as NoIssuesFound } from '../assets/noIssuesFound.svg';
import Organization from '../models/Organization';
import Repository from '../models/Repository';
import styles from '../styles/Issues.module.css';
import { Details, formatIssueDetails } from '../utils/checks';
import { getReadableDateInDays } from '../utils/date';
import { capitalize, plural } from '../utils/text';
import EnhancedTable, { EnhancedTableProps } from './EnhancedTable';
import IssueHelperDrawer from './IssueHelperDrawer';
import IssueParameterFacet from './IssueParameterFacet';
import IssuePullRequestStatusLabel from './IssuePullRequestStatusLabel';
import IssueSeverityLabel from './IssueSeverityLabel';
import Loading from './Loading';
import PageContainer from './PageContainer';
import Selector from './Selector';
import Subtitle from './Subtitle';
import Title from './Title';
import UnexpectedError from './UnexpectedError';

type CheckKey = string;

interface PullRequest {
    id: string;
    title: string;
    number: number;
    status: string;
    repository: string;
    lastDateAt: string;
    check: CheckKey;
    severity: string;
    details: Details;
}

interface PullRequestsWithIssueProps {
    filteredPullRequests: PullRequest[];
    selectedIssueStatus: string;
    togglerIssueHelperDrawer(checkKey: string, open: boolean): (event: any) => void;
    onPullRequestClick?(pullRequest: PullRequest): void;
}

function PullRequestsWithIssue(props: PullRequestsWithIssueProps) {
    const { filteredPullRequests, selectedIssueStatus, togglerIssueHelperDrawer, onPullRequestClick } = props;

    const tableHeadCells: EnhancedTableProps<PullRequest>['heads'] = useMemo(
        () => [
            {
                id: 'id',
                numeric: true,
                disablePadding: false,
                label: 'ID',
                formatLabel: (pullRequest) => `#${pullRequest.number}`,
            },
            {
                id: 'title',
                numeric: false,
                disablePadding: false,
                sortable: true,
                label: 'Title',
                formatLabel: (pullRequest) => {
                    return (
                        <Tooltip title="Click to open pull request on GitHub" placement='bottom'>
                            <div className={styles.pullRequestTitle} onClick={() => onPullRequestClick && onPullRequestClick(pullRequest)}>
                                {pullRequest.title}
                                <OpenInNewIcon />
                            </div>
                        </Tooltip>
                    );
                },
            },
            {
                id: 'check',
                numeric: false,
                disablePadding: false,
                sortable: true,
                label: 'Check',
                formatLabel: (pullRequest) => {
                    return (
                        <Chip
                            className={styles.chip}
                            label={
                                <Tooltip title="Click to see more details">
                                    <div className={styles.checkChip} onClick={togglerIssueHelperDrawer(pullRequest.check, true)}>
                                        <Typography className={styles.checkKey}>
                                            {pullRequest.check}
                                        </Typography>
                                        <HelpOutlineIcon />
                                    </div>
                                </Tooltip>
                            }
                            size='small'
                        />
                    );
                },
            },
            {
                id: 'severity',
                numeric: false,
                disablePadding: false,
                sortable: true,
                label: 'Severity',
                formatLabel: (pullRequest) => <IssueSeverityLabel severity={pullRequest.severity} />,
            },
            {
                id: 'status',
                numeric: false,
                disablePadding: false,
                sortable: true,
                label: 'Status',
                formatLabel: (pullRequest) => {
                    return <IssuePullRequestStatusLabel status={pullRequest.status} />;
                },
            },
            {
                id: 'lastDateAt',
                numeric: true,
                disablePadding: false,
                sortable: true,
                label: 'Spotted On',
                formatLabel: (pullRequest) => `${getReadableDateInDays(pullRequest.lastDateAt)}d ago`,
            },
            {
                id: 'details',
                numeric: false,
                disablePadding: false,
                label: 'Details',
                formatLabel: (pullRequest) => formatIssueDetails(pullRequest.details),
            },
        ],
        [onPullRequestClick, togglerIssueHelperDrawer],
    );

    return (
        <div>
            <Subtitle>
                {filteredPullRequests.length} {selectedIssueStatus !== 'all' && capitalize(selectedIssueStatus)} {plural(filteredPullRequests.length, 'Issue')} on Pull Requests
            </Subtitle>
            <EnhancedTable
                noDataLabel="No pull requests found!"
                defaultOrderBy="lastDateAt"
                heads={tableHeadCells}
                rows={filteredPullRequests}
                itemType="issues"
            />
        </div>
    );
}

interface GetIssuesQuery {
    issues: {
        closed_at: string;
        codehost: string;
        created_at: string;
        opened_at: string;
        repository_id: string;
        target_id: string;
        updated_at: string;
        check_key: string;
        severity: string;
        details: Details;
        pull_request: {
            id: string;
            title: string;
            number: number;
            repository: {
                name: string;
                owner: {
                    login: string;
                };
            };
        };
    }[]
    github_repositories: {
        name: string;
    }[]
}

const getIssuesQuery = gql`
    query GetIssuesQuery($id: String) {
        issues(limit: 100, order_by: {created_at: desc}) {
            closed_at
            codehost
            created_at
            opened_at
            repository_id
            target_id
            updated_at
            check_key
            severity
            details
            pull_request {
                id
                title
                number
                repository {
                    name
                    owner {
                        login
                    }
                }
            }
        }
        github_repositories {
            name
        }
    }
`;

interface IssuesProps {
    organization: Organization;
}

export default function Issues(props: IssuesProps) {
    const { organization } = props;
    const [helperDrawerOpen, setHelperDrawerOpen] = useState(false);
    const [selectedIssueHelp, setSelectedIssueHelp] = useState<CheckKey>();
    const [selectedCheck, setSelectedCheck] = useState<CheckKey | 'all'>('all');
    const [selectedRepository, setSelectedRepository] = useState<string | 'all'>('all');
    const [selectedSeverity, setSelectedSeverity] = useState<string | 'all'>('all');
    const [selectedIssueStatus, setSelectedIssueStatus] = useState<string | 'all'>('open');
    const [issuesCountPerSeverity, setIssuesCountPerSeverity] = useState<Map<string, number>>(new Map<string, number>());
    const [issuesCountPerRepository, setIssuesCountPerRepository] = useState<Map<string, number>>(new Map<string, number>());
    const [issuesCountPerChecks, setIssuesCountPerChecks] = useState<Map<string, number>>(new Map<string, number>());
    const [availableRepositories, setAvailableRepositories] = useState<{
        label: string;
        value: string;
        formatLabel?(value: string): JSX.Element;
    }[]>([]);

    const { data, loading, error } = useQuery<GetIssuesQuery>(getIssuesQuery, {
        variables: { id: organization.id },
    });

    const availableSeverities = useMemo(
        () => [
            { label: 'All', value: 'all', formatLabel: (value: string) => <i>{value}</i> },
            { label: 'High', value: 'high' },
            { label: 'Medium', value: 'medium' },
            { label: 'Low', value: 'low' },
        ],
        [],
    );

    const availableIssueStatus = useMemo(
        () => [
            { label: 'All', value: 'all', formatLabel: (value: string) => <i>{value}</i> },
            { label: 'Open', value: 'open' },
            { label: 'Closed', value: 'closed' },
        ],
        [],
    );

    const availableChecks = useMemo(
        () => {
            return [
                { label: 'All', value: 'all', formatLabel: (value: string) => <i>{value}</i> },
                { label: 'git-conflict', value: 'git-conflict' },
                { label: 'high-internal-code-churn', value: 'high-internal-code-churn' },
                { label: 'high-code-churn', value: 'high-code-churn' },
                { label: 'large', value: 'large' },
                { label: 'long-lived', value: 'long-lived' },
                { label: 'long-pick-up-time', value: 'long-pick-up-time' },
                { label: 'many-comments', value: 'many-comments' },
                { label: 'many-review-iterations', value: 'many-review-iterations' },
                { label: 'non-conventional-commits', value: 'non-conventional-commits' },
                { label: 'stale', value: 'stale' },
                { label: 'unstable-build', value: 'unstable-build' },
            ];
        },
        [],
    );

    const filteredPullRequests = useMemo(
        () => {
            if (!data) {
                return [];
            }

            setAvailableRepositories([
                { label: 'All', value: 'all', formatLabel: (value: string) => <i>{value}</i> },
                ...data.github_repositories.map(repository => ({
                    label: repository.name,
                    value: repository.name,
                }))
            ]);

            return data.issues
                .filter(issue => selectedCheck === 'all' || selectedCheck === issue.check_key)
                .filter(issue => selectedSeverity === 'all' || selectedSeverity === issue.severity)
                .filter(issue => selectedRepository === 'all' || selectedRepository === issue.pull_request.repository.name)
                .filter(issue => selectedIssueStatus === 'all' || selectedIssueStatus === (issue.closed_at ? 'closed' : 'open'))
                .map(issue => ({
                    id: `${issue.pull_request.id}${issue.check_key}`,
                    title: issue.pull_request.title,
                    number: issue.pull_request.number,
                    status: issue.closed_at ? 'closed' : 'open',
                    lastDateAt: issue.closed_at || issue.opened_at || issue.created_at,
                    repository: issue.pull_request.repository.name,
                    check: issue.check_key,
                    severity: issue.severity,
                    details: issue.details,
                }));
        },
        [data, selectedSeverity, selectedRepository, selectedCheck, selectedIssueStatus],
    );

    const handlePullRequestClick = useCallback(
        (pullRequest: PullRequest) => {
            window.open(`https://github.com/${organization.name}/${pullRequest.repository}/pull/${pullRequest.number}`, '_blank');
        },
        [organization.name],
    );

    const handleSelectedRepositoryChange = useCallback(
        (selectedRepository: string) => {
            setSelectedRepository(selectedRepository);
        },
        [],
    );

    const handleSelectedSeverityChange = useCallback(
        (selectedSeverity: string) => {
            setSelectedSeverity(selectedSeverity);
        },
        [],
    );

    const handleSelectedCheckChange = useCallback(
        (selectedCheck: string) => {
            setSelectedCheck(selectedCheck);
        },
        [],
    );

    const togglerIssueHelperDrawer = useCallback(
        (checkKey: string, open: boolean) => (event: any) => {
            event.stopPropagation();
            setSelectedIssueHelp(checkKey);
            setHelperDrawerOpen(open);
        },
        [],
    );

    useEffect(
        () => {
            if (!filteredPullRequests) {
                return;
            }

            const issuesCountPerSeverity = new Map<string, number>();
            const issuesCountPerRepository = new Map<string, number>();
            const issuesCountPerChecks = new Map<string, number>();

            filteredPullRequests.forEach(issue => {
                const currentSeverityCount = issuesCountPerSeverity.get(issue.severity) || 0;
                issuesCountPerSeverity.set(issue.severity, currentSeverityCount + 1);

                const currentRepositoryCount = issuesCountPerRepository.get(issue.repository) || 0;
                issuesCountPerRepository.set(issue.repository, currentRepositoryCount + 1);

                const currentChecksCount = issuesCountPerChecks.get(issue.check) || 0;
                issuesCountPerChecks.set(issue.check, currentChecksCount + 1);
            });

            setIssuesCountPerSeverity(issuesCountPerSeverity);
            setIssuesCountPerRepository(issuesCountPerRepository);
            setIssuesCountPerChecks(issuesCountPerChecks);
        },
        [filteredPullRequests],
    );

    if (error) {
        return <UnexpectedError />;
    }

    if (loading) {
        return <Loading />;
    }

    if (!data || !data.issues.length) {
        return (
            <PageContainer>
                <Title>Issues</Title>
                <div className={styles.noIssuesFound}>
                    <NoIssuesFound />
                    <Typography variant="h6" component="div" sx={{ fontWeight: 700, fontSize: '1.5rem', textAlign: 'center' }}>
                        No issues found!<br />
                        Keep up the good work!
                    </Typography>
                </div>
            </PageContainer>
        );
    }

    return (
        <PageContainer>
            <Title>Issues</Title>
            <div className={styles.filters}>
                <Selector
                    className={styles.filterSelector}
                    label="Severity"
                    value={selectedSeverity}
                    options={availableSeverities}
                    onChange={handleSelectedSeverityChange}
                />
                <Selector
                    className={styles.filterSelector}
                    label="Check"
                    value={selectedCheck}
                    options={availableChecks}
                    onChange={handleSelectedCheckChange}
                />
                <Selector
                    className={styles.filterSelector}
                    label="Repository"
                    value={selectedRepository}
                    options={availableRepositories}
                    onChange={handleSelectedRepositoryChange}
                />
                <Selector
                    className={styles.filterSelector}
                    label="Issue Status"
                    value={selectedIssueStatus}
                    options={availableIssueStatus}
                    onChange={setSelectedIssueStatus}
                />
            </div>
            <div className={styles.main}>
                <div className={styles.facetedFilters}>
                    <IssueParameterFacet
                        label="Severity"
                        value={selectedSeverity}
                        totalIssues={filteredPullRequests.length}
                        items={issuesCountPerSeverity}
                    />
                    <IssueParameterFacet
                        label="Check"
                        value={selectedCheck}
                        totalIssues={filteredPullRequests.length}
                        items={issuesCountPerChecks}
                    />
                    <IssueParameterFacet
                        label="Repository"
                        value={selectedRepository}
                        totalIssues={filteredPullRequests.length}
                        items={issuesCountPerRepository}
                    />
                </div>
                <PullRequestsWithIssue
                    filteredPullRequests={filteredPullRequests}
                    selectedIssueStatus={selectedIssueStatus}
                    togglerIssueHelperDrawer={togglerIssueHelperDrawer}
                    onPullRequestClick={handlePullRequestClick}
                />
            </div>
            <IssueHelperDrawer
                drawerOpen={helperDrawerOpen}
                onDrawerClose={() => setHelperDrawerOpen(false)}
                selectedIssueKey={selectedIssueHelp}
            />
        </PageContainer>
    );
}
