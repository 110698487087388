// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import Logout from '@mui/icons-material/Logout';
import ModeNightIcon from '@mui/icons-material/ModeNight';
import { AppBar, Autocomplete, Avatar, Button, IconButton, ListItemText, Menu, MenuItem, Switch, useColorScheme } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import TextField from '@mui/material/TextField';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ReviewpadLogo } from '../assets/reviewpadLogo.svg';
import { routePaths } from '../constants/routes';
import { useSessionContextLoggedIn } from '../hooks/useSessionContext';
import Organization from '../models/Organization';
import styles from '../styles/Header.module.css';

interface UserOptionsProps {
    installationUrl: string;
    organizations: Organization[];
    selectedOrganization: Organization;
    onChangeOrganization: (event: React.SyntheticEvent, value: Organization | null) => void;
}

function UserOptions(props: UserOptionsProps) {
    const { installationUrl, organizations, selectedOrganization, onChangeOrganization } = props;
    const sessionContext = useSessionContextLoggedIn();
    const { user } = sessionContext;
    const navigate = useNavigate();
    const { mode, setMode } = useColorScheme();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const openMenu = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        },
        [],
    );

    const closeMenu = useCallback(
        () => {
            setAnchorEl(null);
        },
        [],
    );

    const toggleMode = useCallback(
        () => {
            setMode(mode === 'dark' ? 'light' : 'dark');
        },
        [mode, setMode],
    );

    const handleLogout = useCallback(
        () => {
            closeMenu();
            if (sessionContext.loggedIn) {
                sessionContext.logout();
                navigate(routePaths.login);
            }
        },
        [closeMenu, navigate, sessionContext],
    );

    return (
        <div className={styles.userOptions}>
            <div className={styles.organizationSelect}>
                <Autocomplete
                    disableClearable
                    openOnFocus
                    getOptionLabel={(option) => option.name}
                    options={organizations}
                    value={selectedOrganization}
                    onChange={onChangeOrganization}
                    renderInput={(params) => <TextField {...params} label="Reviewpad-Enabled Organizations" />}
                    componentsProps={{
                        paper: {
                            sx: { border: 1, borderColor: 'grey.500' }
                        }
                    }}
                />
                <Button
                    className={styles.addOrganizationButton}
                    href={installationUrl}
                    target="_blank"
                >
                    Add new organization
                </Button>
            </div>
            <div>
                <IconButton onClick={openMenu}>
                    <Avatar
                        alt={`${user.name} avatar`}
                        src={user.avatarUrl}
                    />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={closeMenu}
                >
                    <MenuItem onClick={toggleMode}>
                        <ListItemIcon>
                            <ModeNightIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            Night Mode{' '}
                            <Switch checked={mode === 'dark'} />
                        </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Logout</ListItemText>
                    </MenuItem>
                </Menu>
            </div>
        </div>
    );
}

interface HeaderProps {
    installationUrl: string;
    organizations: Organization[];
    selectedOrganization: Organization;
    onChangeOrganization: (event: React.SyntheticEvent, value: Organization | null) => void;
}

export default function Header(props: HeaderProps) {
    const { installationUrl, organizations, selectedOrganization, onChangeOrganization } = props;
    const navigate = useNavigate();

    const handleRedirectToHomepage = useCallback(
        () => {
            navigate(routePaths.home);
        },
        [navigate],
    );

    return (
        <AppBar className={styles.container} position="sticky" elevation={0} enableColorOnDark>
            <ReviewpadLogo className={styles.logo} onClick={handleRedirectToHomepage} />
            <UserOptions
                installationUrl={installationUrl}
                organizations={organizations}
                selectedOrganization={selectedOrganization}
                onChangeOrganization={onChangeOrganization}
            />
        </AppBar>
    );
}
