// Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
// Use of this source code is governed by a license that can be
// found in the LICENSE file.

import { Typography } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as GitHubLogo } from '../assets/githubLogo.svg';
import { ReactComponent as ReviewpadLogo } from '../assets/reviewpadLogo.svg';
import { routePaths } from '../constants/routes';
import { useSessionContext } from '../hooks/useSessionContext';
import styles from '../styles/Login.module.css';

export default function Login() {
    const sessionContext = useSessionContext();
    const navigate = useNavigate();

    const loginWithGitHub = useCallback(
        () => {
            if (sessionContext.loggedIn) {
                console.error('Already logged in');
                return;
            }

            sessionContext.login.withGitHub();
        },
        [sessionContext],
    );

    useEffect(
        () => {
            if (sessionContext.loggedIn) {
                navigate(routePaths.plan);
            }
        },
        [navigate, sessionContext],
    );

    return (
        <div className={styles.container}>
            <ReviewpadLogo />
            <div className={styles.loginContainer}>
                <Typography variant="h6">
                    Sign in to continue to the App
                </Typography>
                <div className={styles.loginProviders}>
                    <button className={styles.gitHubSignInButton} onClick={loginWithGitHub}>
                        <GitHubLogo />
                        <Typography>
                            Log in with GitHub
                        </Typography>
                    </button>
                </div>
            </div>
        </div>
    );
}
